import React from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "../styles/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function RefundPolicy(props) {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { ...rest } = props;
  return (
    <div style={{ margin: "-8px" }}>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/header-back.jpg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <br />
          <h2
            style={{
              textAlign: isRTL === "rtl" ? "right" : "left",
              position: "relative",
              marginTop: "30px",
              minHeight: "32px",
              color: "#383838",
              textDecoration: "none",
            }}
          >
           {t('refund_policy_title')} 
          </h2>
          <p
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
              {t('refund_policy_para1')} 
          </p>

          <br />

          <p
            className={classes.description}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            <strong>{t('refund_title')} </strong>

            <p>
            {t('refund_thanks')} 
            </p>

            <strong>{t('refund_header1')}</strong>
            <p>
            {t('refund_header1_text')} 
            </p>

            <strong> {t('refund_header2')} </strong>
            <p>
            {t('refund_header2_text')} 
            </p>

            <strong> {t('refund_header3')}</strong>
            <p>
            {t('refund_header3_text')} 
            </p>

            <strong> {t('refund_header4')}</strong>
            <p>
            {t('refund_header4_text')} 
            </p>

            <strong> {t('refund_header5')}</strong>
            <p>
            {t('refund_header5_text')} 
            </p>

            <strong> {t('refund_header6')}6. Legal Jurisdiction</strong>
            <p>
            {t('refund_header6_text')}   
            </p>

            <p>
            {t('refund_header7_text')} 
            </p>
          </p>

          <br />
          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
}
