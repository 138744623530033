const FirebaseConfig = {

	 apiKey: 'AIzaSyCKIAm56ZZQb3FGZ7JRapEqm3wjeIzNUiE',
	 authDomain: 'firstcab-370211.firebaseapp.com',
	 databaseURL: 'https://firstcab-370211-default-rtdb.firebaseio.com',
	 projectId: 'firstcab-370211',
	 storageBucket: 'firstcab-370211.appspot.com',
	 messagingSenderId: '776890384578',
	 appId: '776890384578:web:b6271b541aa6c064c97b5b',
	 measurementId: '776890384578',
};

export default FirebaseConfig;
